
























































































































































import {axiosGet, axiosPatch} from '@/utils/axiosWrapper';
import Vue from 'vue';

export interface IText extends Document {
  page: string;
  EN?: string;
  DE?: string;
  NL?: string;
  description?: string;
  created: Date;
}

export default Vue.extend({
  name: 'Translate',
  data() {
    return {
      query: false,
      uploading: false,

      onlyShowEmpty: "none" as 'none' | "EN" | "DE" | "NL",
      page: "",
      viewOptions: {},
      elemProPage: 20,
      currentPage: 1,

      filteredData: undefined as undefined | IText[],
      myData: undefined as undefined | IText[],
      show: {
        page: true,
        description: true,
        EN: true,
        NL: true,
        DE: true,
      }
    }
  },
  methods: {
    loadData: async function () {
      this.query = true
      this.myData = (await axiosGet('/text')).data
      if (this.myData) {
        this.viewOptions = new Set(this.myData.map(item => item.page))

        this.filterData(this.myData)
      } else {
        console.warn("Could not load any text... This is a problem.")
      }
      this.query = false

    },
    filterData: function (data: IText[]) {
      this.filteredData = data
      this.filteredData = this.filteredData.filter((d) => {
        if (this.onlyShowEmpty !== 'none')
          return !d[this.onlyShowEmpty]
        else
          return d
      })
      console.log(this.filteredData)
      if (this.page)
        this.filteredData = this.filteredData.filter((d) => d.page == this.page)
      console.log(this.filteredData)
    },
    change: async function (id: string, language: "EN" | "DE" | "NL", content: string) {
      this.uploading = true
      await axiosPatch('/text', {
        "_id": id,
        "language": language,
        "text": content
      })
          .then((res) => {
            console.log("Updated text with status: " + res.status)
            this.loadData()
          })
          .catch((error) => {
            alert('Your data could not be saved.\nReloading all data to prevent data loss.\n\n' + error)
            this.loadData()
          });
      this.uploading = false
    },
    filterArrayForPage: function () {
      if (this.filteredData) {
        let minIndex = (this.currentPage - 1) * this.elemProPage
        let maxIndex = (this.currentPage) * this.elemProPage
        return this.filteredData.slice(minIndex, maxIndex)
      } else {
        return []
      }
    },
  },
  mounted() {
    this.loadData()
  }
});
